export default {
    methods: {
        handlerSwitchLanguage(language, key, obj) {
            switch(language) {
                case 'zh':
                    return obj[`${key}Ch`];
                default:
                    return obj[`${key}En`];
            }
        }
    }
}