import Vue from 'vue'
import VueRouter from "vue-router"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('./views/home/index'),
  },
  {
    path: '/resister',
    name: 'resister',
    component: () => import('./views/home/resister'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/login/login/login'),
  },
  {
    path: '/naocuzhong',
    name: 'naocuzhong',
    component: () => import('./views/naocuzhong/index'),
  },
  {
    path: '/hongshouhuan',
    name: 'hongshouhuan',
    component: () => import('./views/hongshouhuan/index'),
  },
  {
    path: '/duiwai',
    name: 'duiwai',
    component: () => import('./views/duiwai/index'),
  },
  {
    path: '/query',
    name: 'query',
    component: () => import('./views/query/index')
  },
  {
    path: '/contentList/:id',
    name: 'ContentList',
    props: true,
    component: () => import('./views/contentList/index')
  },
  {
    path: '/meetList',
    name: 'MeetList',
    component: () => import('./views/meetList/index')
  },
  {
    path: '/meetList/:id',
    name: 'MeetListInfo',
    props: true,
    component: () => import('./views/meetList/info')
  },
  {
    path: '/notice',
    name: 'Notice',
    component: () => import('./views/notice/info')
  },
  {
    path: '/payDeal/:userId',
    name: 'PayDeal',
    component: () => import('./views/payDeal/index')
  },
  {
    path: '/memberCenter',
    name: 'MemberCenter',
    component: () => import('./views/memberCenter/index')
  },
  {
    path: '/livePage',
    name: 'LivePage',
    component: () => import('./views/livePage/index')
  },
  {
    path: '/livePageThree',
    name: 'LivePageThree',
    component: () => import('./views/livePageThree/index')
  },
  {
    path: '/livePageTwo',
    name: 'LivePageTwo',
    component: () => import('./views/livePageTwo/index')
  },
]

export default new VueRouter({
  mode: 'hash',
  routes,
})
