<template>
  <div :id="(pageList.indexOf($route.name) < 0)?'App':'Apps'">
<!--    <BaseHeader/>-->
    <BaseNav v-if="pageList.indexOf($route.name) < 0"/>
    <el-image v-if="isShowBanner"  style="width: 100%;height: 580px"></el-image>
     <router-view></router-view>
    <BaseFooter v-if="pageList.indexOf($route.name) < 0"/>
<!--    <div style="height: 10px;margin-top: 200px"></div>-->
  </div>
</template>

<script>
  // import BaseHeader from "@/components/BaseHeader/BaseHeader";
  import BaseNav from "@/components/BaseNav/BaseNav";
  import BaseFooter from "@/components/BaseFooter/BaseFooter";
  import {getSwnavigationBar} from '@/http/request'
  export default {
    data(){
      return{
        isShowBanner:false,
        pageList: ['LivePage', 'LivePageTwo', 'LivePageThree']
      }
    },
    name: 'App',
    components: { BaseNav, BaseFooter},
    provide: {
      language: location.href.search('/en') > 0 ? 'en' : 'zh'
    },
    created() {
      this.getSwnavigationBar()
    },
    onShow(){
      console.log("onShow")
    },
    methods: {
      getSwnavigationBar() {
            getSwnavigationBar({limit: 100}).then(({data}) => {
                if(data.code === 0) {
                    this.handleResolveData(data.data.list);
                }
            })
        },
      change(val){
        console.log("app:"+val)
      },

        handleResolveData(data) {

            let arr = [];
            data.map((first) => {
                if (first.parent == 0) {
                arr.push(first);
                }
            });
            arr.map((obj1, index) => {
                obj1.index = index + 1;
                data.map((obj2) => {
                if (obj1.id === obj2.parent) {
                    if (obj1.children) {
                    obj1.children.push(obj2);
                    } else {
                    obj1.children = [obj2];
                    }
                }
                });
            });
            // this.dataList = arr;
            this.$store.dispatch('setNav', arr)
        },
    }
  }
</script>
<style lang="less">
  #App {
    min-height: 130vh;
    padding-bottom:360px;
    box-sizing: border-box;
    position: relative;
  }
  #Apps {
    min-height: 100vh;
    // padding-bottom: 300px;
    box-sizing: border-box;
    position: relative;
  }
</style>
