import Vue from "vue"
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
  namespaced: true,
  state: {
    nav: [],
    searchKey: "",
    step1: {},
    step2: {},
    step3: {},
    code:'',
    userInfo:{},
    token:'',
    searchText:'', // 首页搜索框内容
  },

  mutations: {
    set_nav(state, data) {
      state.nav = data
    },
    set_searchKey(state, data) {
      state.searchKey = data
    },
    setRegisterInfo(state, {step,data}) {
      state[step] = data
    },
    setCode(state, data) {
      state.code = data
    },
    // 存用户信息
    setUserInfo(state, data) {
      state.userInfo = data
    },
    // 存token
    setToken(state, data) {
      state.token = data
    },
    // 搜索框内容赋值
    setSearchText(state, data) {
      state.searchText = data
    }
  },

  actions: {
    setNav({commit}, data) {
      commit('set_nav', data)
    },
    setSearchKey({commit}, data) {
      commit('set_searchKey', data)
    }
  }
})