// import store from '../store'
import axios from "axios";
import {getCookie} from '../util/util.js';

const instance = axios.create({
  baseURL:  process.env.VUE_APP_BASE_URL,
  headers: {contentType: 'application/json'}
})

instance.interceptors.request.use(function (config) {
  try {
    config.headers.token = getCookie('token')
  } catch(err) {
    console.log(err)
  }
  return config
}, function (error) {
  return Promise.reject(error);
})

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
})

export default instance
