<template>
  <div class='base-footer'>
    <div class="content">
      <div class="left">
        <p class="blue-tit">联系方式</p>
        <p class="contact-info">
          上海卒中学会（Shanghai Stroke Association)<br>
          地址：上海市南京西路1486号东海广场3号楼908室 上海卒中学会—秘书办公处<br>
          Email：ssa@shanghaistroke.org.cn<br>
          联系电话：021-62299150<br>
          邮编：200040
        </p>
      </div>
      <div class="frd-link">
        <p class="blue-tit">友情链接</p>
        <el-row>

          <el-col :span="6">
            <a href="https://www.cma.org.cn/" title="中华医学会" target="_blank"><img class="col-image" src="../../assets/images/logo3.png"></a>
          </el-col>
          <el-col :span="6">
            <a href="http://www.cmda.net/" title="中国医师协会" target="_blank"><img class="col-image" src="../../assets/images/logo10.jpeg"></a>
          </el-col>

          <el-col :span="6">
            <a href="https://chinastroke.net/#/" title="中国卒中学会" target="_blank"><img class="col-image" src="../../assets/images/logo1.png"></a>
          </el-col>
          <el-col :span="6">
            <a href="http://www.shsma.org.cn/" title="上海市医学会" target="_blank"><img  class="col-image" src="../../assets/images/logo2.png"></a>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <a href="http://zhiyuanzt.kechuangfu.com/" title="红手环" target="_blank"><img class="col-image" src="../../assets/images/logo5.png"></a>
          </el-col>
          <el-col :span="6">
            <a href="http://mzj.sh.gov.cn/gb/shmzj/index.html" title="上海市民政局" target="_blank"><img class="col-image" src="../../assets/images/minzhengju.png"></a>
          </el-col>
          <el-col :span="6">
            <a href="http://www.sast.gov.cn/" title="上海市科学技术学会" target="_blank"><img class="col-image" src="../../assets/images/kexue.png"></a>
          </el-col>

        </el-row>
        <br><br>
      </div>
      <div class="right">
        <p class="blue-tit">微信公众号</p>
        <img src="../../assets/images/qr.jpg" style="height: 80px;width: 80px">
      </div>
    </div>
    <div style="clear: both"></div>
    <div  class="copyright" >
      <div> <a href="https://beian.miit.gov.cn/#/Integrated/index" title="沪ICP备19034691号-2" target="_blank">沪ICP备19034691号-2</a></div>

      <div>Copyright © 2021-2022 by Shanghai Stroke Association. All rights reserved</div>
    </div>

  </div>

</template>

<script>
export default {
    name: 'BaseFooter'
}
</script>

<style lang='less'>
    .footer {
      width: 100%;
      padding: 35px 0;
      background-color: #F2F2F2;
      overflow: hidden;
      margin-top: 100px;
      float: bottom;
    }
    .base-footer {
        width: 100%;
        height: 305px;
        background: rgb(246, 247, 247);
        position: absolute;
        overflow: hidden;
        padding-top: 40px;
        //position: relative;
        left: 0;
        bottom: 0;
        z-index: 1;
        font-size: 13px;
        color: #575757;
        //& > div {
        //    width: 933px;
        //    margin: 0 auto;
        //    padding-top: 25px;
        //    display: flex;
        //    justify-content: space-between;
        //    align-items: center;
        //    img {
        //        width: 50px;
        //        height: 50px;
        //        margin-right: 35px;
        //    }
        //    span:first-child {
        //        display: flex;
        //        align-items: center;
        //    }
        //}
    }
    .copyright {
      font-size: 14px;
      line-height: 48px;
      text-align: center;
      color: #666;
    }
    .content {
      width: 1200px;
      margin: 0px auto;
    }
    .left {
      float: left;
    }
    .blue-tit {
      font-size: 16px;
      color: #014DA3;
      margin-bottom: 20px;
    }
    .contact-info {
      font-size: 14px;
      color: #333;
      line-height: 24px;
    }
    .frd-link {
      float: right;
      margin-left: 40px;
    }
    .blue-tit {
      font-size: 16px;
      color: #014DA3;
      margin-bottom: 20px;
    }
    .right {
      float: right;
    }
    .frd-link .a {
      margin-right: 20px;
    }
    .frd-link .a .img{
      height: 10px;
      width: 10px;

    }
    .frd-link .el-row .el-col{
      padding-right: 20px;
      padding-bottom: 20px;
    }
    .col-image{
      height: 50px;
      width: 50px;
    }


</style>