import Vue from 'vue'
import {
  Tabs,
  TabPane,
  Carousel,
  CarouselItem,
  Input,
  Form,
  FormItem,
  Button,
  Menu,
  MenuItem,
  Submenu,
  Select,
  Option,
  Divider,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Icon,
  Pagination,
  Image,
  Tag,
  Tooltip
} from 'element-ui'
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Button)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Select)
Vue.use(Option)
Vue.use(Divider)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Icon)
Vue.use(Pagination)
Vue.use(Image)
Vue.use(Tag)
Vue.use(Tooltip)