import axios from './instanceAxios'
import {
  interfaceSwBranch,
  interfaceSwtopmenu,
  interfaceSwnavigationBar,
  interfaceSwBanner,
  interfaceSwNotice,
  interfaceSwLogo,
  interfaceSwscrollchart,
  interfaceSwadvertisement,
  interfaceSwArticle,
  interfaceSwArticleReadNum,
  interfaceSwMeet,
  interfaceSwMeetInfo, interfaceSwPhoto, interfaceSwReg, interfaceSwLogin,interfaceSwSendEmail,
  interfaceSwUserInfo,
  interfaceSwPayInfo,
  interfaceSwDocument
} from "./interface";

// 获取专区分会
export const getSwBranchList = (params) => {
  return axios({
    url: interfaceSwBranch,
    method: 'get',
    params
  })
}
// 获取历年照片
export const getPhotos = () => {
  return axios({
    url: interfaceSwPhoto,
    method: 'get',

  })
}

// 获取上浮菜单
export const getSwtopmenuList = (params) => {
  return axios({
    url: interfaceSwtopmenu,
    method: 'get',
    params
  })
}

// 获取导航栏
export const getSwnavigationBar = (params) => {
  return axios({
    url: interfaceSwnavigationBar,
    method: 'get',
    params
  })
}

// 获取banner
export const getSwBanner = (params) => {
  return axios({
    url: interfaceSwBanner,
    method: 'get',
    params
  })
}

// 获取公告
export const getSwNotice = (params) => {
  return axios({
    url: interfaceSwNotice,
    method: 'get',
    params
  })
}

// logo
export const getSwLogo = (params) => {
  return axios({
    url: interfaceSwLogo,
    method: 'get',
    params
  })
}

// 图片滚动区域
export const getSwscrollchart = (params) => {
  return axios({
    url: interfaceSwscrollchart,
    method: 'get',
    params
  })
}


// 广告
export const getSwadvertisement = (params) => {
  return axios({
    url: interfaceSwadvertisement,
    method: 'get',
    params
  })
}

// 文章列表
export const getSwArticle = (params) => {
  return axios({
    url: interfaceSwArticle,
    method: 'get',
    params
  })
}

// 文章列表
export const articleReadNum = (id) => {
  return axios({
    url: `${interfaceSwArticleReadNum}/${id}`,
    method: 'get',
  })
}

// 会议
export const getSwMeet = (params) => {
  return axios({
    url: interfaceSwMeet,
    method: 'get',
    params
  })
}

// 会议详情
export const getSwMeetInfo = (id) => {
  return axios({
    url: `${interfaceSwMeetInfo}/${id}`,
    method: 'get'
  })
}

// 注册
export const register = (data) => {
  return axios({
    url: interfaceSwReg,
    method: 'post',
    data:data
  })
}
// 登录
export const login = (data) => {
  return axios({
    url: interfaceSwLogin,
    method: 'post',
    data:data
  })
}
// 获取验证码
export const getMsgCode = (data) => {
  return axios({
    url: `${interfaceSwSendEmail}/${data}`,
    method: 'post',
  })
}
// 获取个人信息
export const getUserInfo = () => {
  return axios({
    url: interfaceSwUserInfo,
    method: 'get',
  })
}
// 获取支付信息接口
export const getPayInfo = (data) => {
  return axios({
    url: `${interfaceSwPayInfo}/${data}`,
    method: 'get',
  })
}
// 获取支付信息接口
export const documentSearch = (data) => {
  return axios({
    url: `${interfaceSwDocument}`,
    method: 'get',
    params: data
  })
}