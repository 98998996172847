export const interfaceSwBranch = '/swbranch/page' // 专区分会接口
export const interfaceSwtopmenu = '/swtopmenu/page' // 上浮菜单
export const interfaceSwnavigationBar = '/swnavigationbar/page' // 导航栏
export const interfaceSwBanner = '/swcarouselmap/page' // 导航栏
export const interfaceSwNotice = '/swnotice/page' // 公告
export const interfaceSwLogo = '/swlogo/page' // logo区域
export const interfaceSwscrollchart = '/swscrollchart/page' // 图片滚动区域
export const interfaceSwadvertisement = '/swadvertisement/page' // 广告
export const interfaceSwArticle = '/article/page' // 栏目
export const interfaceSwArticleReadNum = '/article/readNum' // 栏目
export const interfaceSwMeet = '/swmeeting/page' // 会议
export const interfaceSwMeetInfo = '/swmeeting' // 会议详情
export const interfaceSwPhoto = '/photo'
export const interfaceSwReg = '/register'
export const interfaceSwLogin = '/login'
export const interfaceSwSendEmail = '/sendEmail'
export const interfaceSwUserInfo = '/api/userInfo'
export const interfaceSwPayInfo = '/pay/createNative'// 获取支付信息
export const interfaceSwDocument = '/article/pageForSearch'// 文献查询