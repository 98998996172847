<template>
    <div class="base-nav">
        <div class='base-nav-wrap'>
            <el-menu defaultOpens="" :default-active="activeIndex" mode="horizontal" @select="handlerSelect" active-text-color="rgba(20, 153, 220, 1)">
                <el-menu-item>
                  <img src="../../assets/images/logo.png" style="padding-top: 5px;width:50px">
                </el-menu-item>
                <el-menu-item index="/" >
                    <router-link to="/">{{dict[language].home}}</router-link>
                </el-menu-item>
                <template v-for="(nav, index) in dataList">
                    <el-submenu
                        :key="index"
                        :index="nav.id"
                        v-if="nav.status && nav.type == 0">
                        <template slot="title">{{handlerSwitchLanguage(language, 'name', nav)}}</template>
                        <el-menu-item
                            v-for="child in filterMenu(nav.children||[])" :key="child.id"
                            :index="handlePath([nav, child, handlerSwitchLanguage(language, 'name', nav), handlerSwitchLanguage(language, 'name', child)])">
                            {{handlerSwitchLanguage(language, 'name', child)}}
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item style="margin-bottom: 20px"
                        :index="handlePath([nav, nav, handlerSwitchLanguage(language, 'name', nav)])"
                        :key="index" v-else-if="nav.status">
                        {{handlerSwitchLanguage(language, 'name', nav)}}
                    </el-menu-item>
                </template>
              <el-menu-item index="/login" v-if="!isLogin" >
                <el-button
                    v-if="!(token || storeToken)"
                    type="primary"
                    @click="toLogin"
                >登录/注册</el-button>
                <!-- <router-link v-if="!(token || storeToken)" to="/login">登录/注册</router-link> -->
                <!-- <router-link v-else to="/login">{{userInfo.username}}</router-link> -->
                <el-dropdown v-else >
                    <el-button type="text" >
                        <span class="cn-tit">您好，{{this.username || userInfo.username}}</span>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="logOut">退出登录</el-dropdown-item>
                        <el-dropdown-item @click.native="toMemberCenter">个人中心</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
              </el-menu-item>
              <el-menu-item  >
                <!--       如果登录状态 进个人中心         -->
                <el-button

                    type="primary"
                    @click="toUser"
                >员工登录</el-button>
              </el-menu-item>
              <el-menu-item  >
                <el-button

                    type="primary"
                    @click="toEmail"
                >邮箱登录</el-button>
              </el-menu-item>
              <el-menu-item index="/login" v-if="isLogin">
                <!--       如果登录状态 进个人中心         -->
                <router-link to="/login">个人中心</router-link>
              </el-menu-item>

            </el-menu>


        </div>
    </div>
</template>

<script>
  import {mapState} from 'vuex'
  import dict from '@/assets/json/dictionaries.json'
  import mixs from "@/mixs"
//   import Vue from 'vue'
  import {getCookie,delCookie} from '../../util/util.js';

  export default {
    name: "BaseNav",
    mixins: [mixs],
    inject: ['language'],
    // beforeMount () {
    //     Vue.prototype.$cookieStore = {
    //     getCookie,
    //     delCookie
    // }},
    data() {
        return {
            showHomeNav: false,
            activeIndex: null,
            isLogin:false,
            username: '',
            dict,
            token:'',
        }
    },
    watch: {
        "$route"(_new) {
            this.showHomeNav = _new.path !== "/"
            this.activeIndex = null
            this.validLogin()
        },
    },
    computed: {
        ...mapState({
            dataList: state => state.nav,
            userInfo: state => state.userInfo,
            storeToken: state => state.token,
        }),
        filterMenu () {
            return menu => {
                return menu.filter((item) => {
                    return item.status === 1
                })
            }

        },
    },
    methods: {
      toUser(){
        window.open("http://pm.shanghaistroke.org.cn")
      },
      toEmail() {
        window.open("https://qy.163.com/login/")
      },
        // 退出登录
        logOut() {
            this.$store.commit('setUserInfo', {})
            this.$store.commit('setToken', '')
            this.$router.push('/login')
            delCookie('token');
            delCookie('username');
            delCookie('userId');
            this.token = ''
            this.username = ''
        },
        toLogin() {
            this.$router.push('/login')
        },
        // 个人中心跳转
        toMemberCenter() {
            this.$router.push('/memberCenter')
        },
        validLogin(){
          // 效验登录状态
          // 1 效验token是否有效 并更新用户信息 赋值用户名给成员变量 并保留在nav位置 如果失效 弹message 登录失效,请重新登录 (如果没有token 则不判断 默认未登录状态)
          this.isLogin  = false

        },
        handlerSelect(json) {
            if(json === '/') return;
            if(json === '/login') return;
            const obj = JSON.parse(json);
            if (obj?.evtObj.type === 1) { // 外链
                window.open(this.handlerSwitchLanguage(this.language, 'url', obj.evtObj))
            } else if(obj?.evtObj.type === 2) { // 导航类
                this.$router.push({
                    path: obj.to,
                    query: {
                        paths: JSON.stringify(obj.path),
                        menu: JSON.stringify(obj.menu)
                    }
                })
            }
        },

        handlePath([menu, evtObj, ...paths]) {
          // console.log(menu)
          // console.log(evtObj)
          // console.log(paths)
            const obj = {
                to: `/contentList/${evtObj.id}`,
                path: paths,
                evtObj,
                menu
            }
            return JSON.stringify(obj)
        }
    },
    mounted() {
        this.token = getCookie('token')
        this.username = getCookie('username')
    },
  }
</script>

<style lang="less">
    .base-nav {
        //margin: 0 auto;
        padding-top: 20px;
        background-color: #ffffff;
        border-bottom: 1px solid #dcdfe6;
        position: -webkit-sticky;
        position: sticky;

        top: 0;
        z-index: 10;
        pointer-events: none;
        & > .base-nav-wrap {
            width: max-content;
            margin: 0 auto;
            pointer-events: initial;
            .base-nav-item {
                height: 40px;
                display: inline-block;
                //margin: 0 20px;
                font-size: 20px;
                color: rgba(89, 87, 87, 1);
                cursor: pointer;
                &.active {
                    color: rgba(20, 153, 220, 1);
                    position: relative;
                    &::after {
                        content: '';
                        width: 100%;
                        height: 80px;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        background: rgba(20, 153, 220, 1);
                    }
                }
            }
            .el-menu.el-menu--horizontal {
                border: none;
            }
        }
        .el-menu--horizontal>.el-submenu .el-submenu__title, .el-menu-item {
            font-size: 17px;
            font-weight: 500;
            color: #2f2f2f !important;
        }
        .el-menu--horizontal>.el-menu-item.is-active {
            border-bottom: 4px solid #409EFF;
        }
    }
    .el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title {
        background: #000;
        color: #fff !important;
    }
    .el-menu--collapse .el-menu .el-submenu, .el-menu--popup {
        min-width: 135px;
        padding: 0px;
    }
    .login-nav{
      //position: relative;
      //float: right;
    }

</style>
